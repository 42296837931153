import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store'
import { toggleMenu, closeMenu } from './composables/menu'

class UcaMenu extends HTMLElement {
    constructor() {
        super()

        store.opener = document.getElementById(this.dataset.opener)
        if (!store.opener) {
            console.error('l\'option data-opener n\'est pas remplie ou ne pointe pas sur id')
            return
        }

        this.appDiv = document.createElement('div')
        this.appDiv.setAttribute('id', 'ucaMenu')
        this.appDiv.style.display = 'none'

        const top = this.dataset.top && !isNaN(this.dataset.top) ? parseInt(this.dataset.top) : 0
        this.appDiv.style.top = top + 'px'
        this.appDiv.style.height = 'calc(100% - ' + top + 'px)'

        // https://stackoverflow.com/questions/49823270/naming-convention-for-data-attribute-value-when-used-specifically-for-javascript
        // data attribute from component is named toggle-class, JS transform this with camel style
        this.toggleOutside = (this.dataset.toggleOutside ?? false) === 'true'
        store.openerClass = this.dataset.openerClass ?? (this.dataset.toggleClass ?? '')
        this.client = this.dataset.client ?? ''
        this.connected = this.dataset.connected ?? ''
        this.pathLogin = this.dataset.pathLogin ?? process.env.VUE_APP_CAS_LOGIN_URL + '?service=' + window.location.origin
        this.pathLogout = this.dataset.pathLogout ?? process.env.VUE_APP_CAS_LOGOUT_URL + '?service=' + window.location.origin

        this.appendChild(this.appDiv)

        createApp(App, {
            client: this.client,
            connected: this.connected,
            pathLogin: this.pathLogin,
            pathLogout: this.pathLogout,
        })
        .mount('#ucaMenu')

        store.ucaMenu = this.appDiv
    }

    connectedCallback () {
        // Allow the toggle click if an opener exists
        if (store.opener) {
            store.opener.addEventListener('click', () => {
                toggleMenu()
            })
            // Close the menu if click anywhere outside the menu and the opener
            if (this.toggleOutside) {
                window.addEventListener('click', (event) => {
                    if (!event.target.closest('#' + store.ucaMenu.id) && !store.opener.contains(event.target)) {
                      closeMenu()
                    }
                })
            }
        }
    }

}

customElements.define('uca-menu', UcaMenu)