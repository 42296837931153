import { store } from "../store"

export function openMenu() {
  if (store.ucaMenu) {
    store.ucaMenu.style.display = 'flex'
    if (store.opener && store.openerClass !== '')
        store.opener.classList.add(store.openerClass)
  }
}

export function closeMenu() {
  if (store.ucaMenu) {
    store.ucaMenu.style.display = 'none'
    if (store.opener && store.openerClass !== '')
        store.opener.classList.remove(store.openerClass)
  }
}

export function toggleMenu() {
  store.ucaMenu.style.display != 'none' ? closeMenu() : openMenu()
}

export function toggleMenuDetails() {
  if (!store.menuDetailsIsShown) {
    store.menuSupportIsShown = false
    store.menuItemsIsShown = true
  }
  store.menuDetailsIsShown = !store.menuDetailsIsShown
  const ucaMenu = document.getElementById('ucaMenu')
  if (store.menuDetailsIsShown) {
    ucaMenu.classList.add('menuDetailsIsShown')
  } else {
    ucaMenu.classList.remove('menuDetailsIsShown')
  }

}