import axios from 'axios'
import { store } from '../store'

const httpClient = axios.create({
    // withCredentials: true,
    // credentials: 'same-origin',
    // mode: 'no-cors',
    baseURL: process.env.VUE_APP_ENT_API_URL,
    timeout: 2000, // indicates, 1000ms ie. 1 second
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Methods': '*',
        'Authorization': 'Bearer ' + store.getUserToken(),
        // anything you want to add to the headers
    }
});


export default httpClient