<template>
  <div id="menuToggleDetails" :class="{'bounce': !store.menuDetailsIsShown, 'rotate': store.menuDetailsIsShown}">
    <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>
  </div>
</template>

<script>
import { store } from '../store'

export default {
  name: 'menuToggleDetails',
  data() {
    return {
      store,
    }
  },
}
</script>

<style>
#menuToggleDetails {
  align-items: center;
  display: flex;

  width: 24px;
  background-color: #EFEFEF;
  cursor: pointer;

  z-index: 63002;
}
#menuToggleDetails:hover {
  background-color: #006D82;
}
#menuToggleDetails > svg {
  transition: transform .3s linear;
}
#menuToggleDetails:hover > svg {
  color: #EFEFEF;
}
#menuToggleDetails.bounce > svg {
  animation: bounce 1.5s ease 2s 2;
}
#menuToggleDetails.rotate > svg {
  transform: rotate(-180deg);
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-10px);}
	60% {transform: translateY(-5px);}
}

@media screen and (max-width: 768px) {
  #menuToggleDetails {
    display: none;
  }
}
</style>