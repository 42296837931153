import httpClient from "./httpClient"
import { store } from '../store'

const END_POINT = '/menu'

class Menu {
  getDisconnected() {
    httpClient.get(END_POINT + '/disconnected')
      .then(response => {
        store.setDisconnectedItems(response.data)
      })
      .catch(() => {
      // keep store value if error, clear is done on logout
      })
      .finally(() => store.showLoading(false))
  }

  
  getUsed() {
    httpClient.get(END_POINT + '/used')
      .then(response => {
        store.usedItems = response.data
      })
      .catch(() => {
        store.usedItems = []
      })
      .finally(() => store.showLoading(false))
  }

  async getMine() {
    await httpClient.get(END_POINT)
      .then(response => {
        store.commonItems = response.data.commonMenus
        store.usedItems = response.data.usedMenus
        store.allItems = response.data.allMenus
        if (store.allItems.length > 0)
          store.displayedCategory = store.allItems[0].id
      })
      .catch(() => {
        store.commonItems = []
        store.usedItems = []
        store.allItems = []
      })
      .finally(() => store.showLoading(false))
  } 

  accessCount(menuId) {
    httpClient.get(END_POINT + '/' + menuId + '/count')
      .then(response => {
        console.log(response.data.message);
      })
      .catch(() => {
        console.error('Error to count the access to this menu');
      })
  }

}
export default new Menu()