import httpClient from './httpClient'
import { store } from '../store'

const END_POINT = '/user'

class User {
  async getToken() {
    let receivedToken = false

    await httpClient.get('/get_token?c=' + store.client + '&u=' + store.connected)
      .then(response => {
        if (response.data.token) {
          store.setUserToken(response.data.token)
          receivedToken = true
          
          httpClient.defaults.headers.Authorization = 'Bearer ' + store.getUserToken()

          let payload = this.parseJwt(store.getUserToken())
          // console.log('payload', payload)
          store.userName = payload.fullName
        }
      })
      .catch(error => {
        console.log(error);
      })

    return receivedToken
  }

  async checkToken() {
    let isVerified = false
    await httpClient.get('/check_token')
      .then(response => {
        if (response.status == 200) {
          isVerified = true

          let payload = this.parseJwt(store.getUserToken())
          // console.log('payload', payload)
          store.userName = payload.fullName
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log('error with response', error.response.status)
        } else if (error.request) {
          // The request was made but no response was received
          // console.log('error without response', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message)
        }
      })

    return isVerified
  }

  getPhoto() {
    httpClient.get(END_POINT + '/photo')
      .then(response => {
        if (response.data.photo) {
          store.userPhoto = response.data.photo
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  logout() {
    store.clear()
  }

  // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
  parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
}


export default new User()