<template>
  <MenuMaster :pathLogin="pathLogin" :pathLogout="pathLogout"/>
  <MenuToggleDetails v-if="store.isConnected && store.allItems.length > 0" @click.stop="toggleMenuDetails" />
  <transition name="slide">
    <MenuDetails v-if="store.isConnected" v-show="store.menuDetailsIsShown" :class="{isShown: store.menuDetailsIsShown}"/>
  </transition>
</template>

<script>
import MenuMaster from './components/MenuMaster.vue'
import MenuToggleDetails from './components/MenuToggleDetails.vue'
import MenuDetails from './components/MenuDetails.vue'
import User from '@/api/user.api'
import Menu from '@/api/menu.api'
import { store } from './store'

export default {
  name: 'App',
  components: {
    MenuMaster,
    MenuToggleDetails,
    MenuDetails,
  },
  props: ['client', 'connected', 'pathLogin', 'pathLogout'],
  data() {
    return {
      store,
    }
  },
  methods: {
    toggleMenuDetails() {
      if (!store.menuDetailsIsShown) {
        store.menuSupportIsShown = false
        store.menuItemsIsShown = true
      }
      store.menuDetailsIsShown = !store.menuDetailsIsShown
      const ucaMenu = document.getElementById('ucaMenu')
      if (store.menuDetailsIsShown) {
        ucaMenu.classList.add('menuDetailsIsShown')
      } else {
        ucaMenu.classList.remove('menuDetailsIsShown')
      }

    },
  },
  created() {
    store.showLoading(true)

    store.client = this.client
    store.connected = this.connected
    //TODO refactor and optimize this test
    store.isConnected = store.connected != '' && store.connected != 'false'

    if (store.isConnected === false) {
      // Remove the user token to prevent multi session
      store.clear()

      Menu.getDisconnected()

    } else {

      (async () => {
        let isVerified = await User.checkToken()
        if (!isVerified)
          isVerified = await User.getToken()

        if (isVerified) {
          (async ()=> {
            store.alert = ''

            await Menu.getMine()

            if (store.usedItems.length == 0) {
              store.alert+= 'Problème de récupération de vos items les plus utilisés.<br>'
            }

            if (store.commonItems.length == 0) {
              store.alert+= 'Problème de récupération du menu selon votre profil.<br>'
              Menu.getDisconnected()
            }

            if (store.allItems.length == 0) {
              store.alert+= 'Problème de récupération de tous les items.<br>'
            }

            if (store.alert)
              store.alert+= 'Merci de rafraîchir la page ou réessayer plus tard.'
          })()

          User.getPhoto()
        } else {
          store.alert = 'Impossible de vous identifier'
          // The authentication has failed
          store.clear()

          Menu.getDisconnected()
        }

      })()

    }


    /**
     * //TODO
     * Improve set the menuDetailsIsShown on mobile (and resize)
     */
    window.addEventListener('resize', (e) => {
      showHideAndResize(e, this)
    })
    window.addEventListener('load', (e) => {
      showHideAndResize(e, this)
    })

    function showHideAndResize(event) {
      let width = event.currentTarget.innerWidth
      store.menuDetailsIsShown = width < 769 ? true : false
    }
  },
}
</script>

<style>
@font-face {
  font-family: 'Barlow';
  src: url('https://cdn.uca.fr/fonts/Barlow-Regular.woff2') format('woff2');
}
#ucaMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  font-family: Barlow;
  color: #3D3D3C;
  background-color: transparent;

  z-index: 63000;
  overflow: auto;
}
#ucaMenu * {
  box-sizing: border-box;
}
@media screen and (min-width: 769px) {
  #ucaMenu {
    flex-direction: row;
    width: auto;
  }
  #ucaMenu.menuDetailsIsShown {
    width: 100%;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: transform .5s ease-out;
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(-100%);
  }
}
</style>
