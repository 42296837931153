<template>
  <div id="menuDetails">

    <!-- <div id="searchDetails">
      <input type="text" placeholder="Rechercher une application, un service">
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
      </svg>
    </div> -->

    <div id="listItemsDetails" v-show="store.menuItemsIsShown">
      <div class="categoryAndItems" v-bind:class="{ active: isShown(category.id)}" v-for="category in store.allItems" :key="category.id">
        <div class="categoryTitle" @click="toggleItems(category.id)">
          <div class="categoryChevron up" v-show="!isShown(category.id)"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" /></svg></div>
          <div class="categoryChevron down" v-show="isShown(category.id)"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg></div>
          <div class="categoryName">{{ category.name }}</div>
        </div>
        <MenuItems v-show="isShown(category.id)" :items="category.items" :withDescription="true"/>
      </div>
    </div>

  </div>
</template>

<script>
import { store } from '../store.js'
import MenuItems from './MenuItems.vue'

export default {
  name: 'menuDetails',
  components: { MenuItems },
  data() {
    return {
      store,
    }
  },
  methods: {
    isShown(categoryId) {
      return store.displayedCategory == categoryId
    },
    toggleItems(categoryId) {
      store.displayedCategory = store.displayedCategory == categoryId ? 0 : categoryId
    }
  }
}
</script>

<style>
#menuDetails {
  flex: 1 auto;
  background-color: #F7F7F7;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  z-index: 63001;
}
#searchDetails {
  display: none;
}

#listCategoriesDetails {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}

#listItemsDetails {
  padding: 16px;
  width: 100%;
  height: 100%;

  background-color: #FFF;
}
.categoryAndItems {
  background-color: #F7F7F7;
  padding: 8px 8px 16px;
  margin: 16px 0;
  border-radius: 8px;
}
.categoryAndItems.active {
  background-color: #ECF8F9;
}
.categoryTitle {
  padding: 8px 32px 8px 8px;
  font-weight: bold;
  text-align: center;
}
.categoryTitle:hover,
.categoryAndItems.active > .categoryTitle {
  cursor: pointer;
  color: #006D82;
}
.categoryChevron {
  float: left;
}
.categoryName {
  margin-left: 32px;
  white-space: nowrap;
}

@media screen and (min-width: 769px) {
  #searchDetails {
    margin: 16px;
    width: 70%;
    display: flex;
    align-items: center;
    position: relative;
    display: initial;
  }
  #searchDetails > input {
    width: 100%;
    height: 48px;
    padding: 12px 52px 12px 16px;
    border: 1px solid #CACAC9;
    border-radius: 12px;
    color: #3D3D3C;
  }
  #searchDetails > input::placeholder
  {
    font-family: Barlow;
    font-size: 16px;
    color: #A8A8A7;
  }
  #searchDetails > svg {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  #listItemsDetails {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #F7F7F7;
    overflow: auto;
  }
  #listItemsDetails::before {
    content: "\00a0";
    position: absolute;
    top: 64px;
    width: 100%;
    box-shadow: inset 0px -1px 0px rgb(0 0 0 / 10%);
  }
  .categoryAndItems {
    padding: 0;
    margin: 0;
  }
  .categoryAndItems.active {
    background-color: initial;
  }
  .categoryChevron {
    display: none;
  }
  .categoryTitle {
    padding: 24px;
    color: #3D3D3C;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  .categoryTitle:hover,
  .categoryAndItems.active > .categoryTitle {
    box-shadow: inset 0px -1px 0px rgba(0, 109, 130, 0.9);
  }
  .categoryName {
    margin-left: 0;
  }
  #listItemsDetails > .categoryAndItems > .menuItems {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 20px;
    /* height: 500px; */
    /* align-content: center; */
  }
  #listItemsDetails > .categoryAndItems > .menuItems > .menuItem {
    width: 350px;
  }
}
</style>