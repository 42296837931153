<template>
  <div id="menuMaster">

    <div id="menuAccount" v-if="store.isConnected">
      <div id="userSpace">
        <div id="nameSpace">
          <img id="userImage" :src=store.userPhoto>
          <div id="userName">
            <span>{{ store.userName }}</span>
            <div class="link-with-icon">
              <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8 13C6.14 13 4.59 14.28 4.14 16H2V18H4.14C4.59 19.72 6.14 21 8 21S11.41 19.72 11.86 18H22V16H11.86C11.41 14.28 9.86 13 8 13M8 19C6.9 19 6 18.1 6 17C6 15.9 6.9 15 8 15S10 15.9 10 17C10 18.1 9.1 19 8 19M19.86 6C19.41 4.28 17.86 3 16 3S12.59 4.28 12.14 6H2V8H12.14C12.59 9.72 14.14 11 16 11S19.41 9.72 19.86 8H22V6H19.86M16 9C14.9 9 14 8.1 14 7C14 5.9 14.9 5 16 5S18 5.9 18 7C18 8.1 17.1 9 16 9Z" />
              </svg>
              <a :href="ent_url + '/compte/'">Mon compte</a>
            </div>
          </div>
        </div>
        <a id="close" @click="closeMenu" alt="Fermer le menu" title="Fermer le menu">
          <svg style="width: 24px; height: 24px; vertical-align: baseline;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
          </svg>
        </a>
      </div>
      <div id="mainLinks">
        <div class="link-with-icon">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
          </svg>
          <a :href="ent_url + '/core/support'" target="_blank">Assistance</a>
        </div>
        <div class="link-with-icon">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z"></path>
          </svg>
          <a :href="ent_url">ENT</a>
        </div>
        <a id="deconnexion" @click="logout" alt="Me déconnecter" title="Me déconnecter">
          <svg style="width: 24px; height: 24px; vertical-align: baseline;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13,3H11V13H13V3M17.83,5.17L16.41,6.59C18.05,7.91 19,9.9 19,12A7,7 0 0,1 12,19C8.14,19 5,15.88 5,12C5,9.91 5.95,7.91 7.58,6.58L6.17,5.17C2.38,8.39 1.92,14.07 5.14,17.86C8.36,21.64 14.04,22.1 17.83,18.88C19.85,17.17 21,14.65 21,12C21,9.37 19.84,6.87 17.83,5.17Z" />
          </svg>
        </a>
      </div>

    </div>

    <div id="menuAccount" v-else>
      <a id="connexion" @click="connexion">Me connecter</a>
    </div>

    <div id="alert" v-show="store.alert" v-html="store.alert"></div>

    <div id="menuCommon" v-if="store.isConnected && store.commonItems.length > 0">
      <MenuItems :items="store.commonItems"/>
    </div>

    <div id="menuDisconnected" v-else>
      <MenuItems :items="store.disconnectedItems" :withDescription="!store.isConnected"/>
    </div>

    <div id="menuUsed" v-if="store.isConnected && store.usedItems.length > 0">
      <p class="menuTitle">Mes services et applications</p>
      <p class="menuSubTitle">Vous retrouvez ici vos liens les plus utilisés sur ces 6 derniers mois (hors liens au dessus). La liste est rafraîcie toutes les 24h.</p>
      <MenuItems :items="store.usedItems"/>
    </div>

    <div id="allApplications" v-if="store.isConnected && store.allItems.length > 0">
      <a @click="toggleMenuDetails">
        Tous les services / applications
        <svg style="width: 18px; height: 18px;" viewBox="0 0 24 24"><path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" /></svg>
      </a>
    </div>

    <div id="ucaMenuLoader" v-show="store.isLoading" style="width:50px; height:50px;"></div>
  </div>
</template>

<script>
import MenuItems from './MenuItems.vue'
import { store } from '../store.js'
import { closeMenu, toggleMenuDetails } from '../composables/menu';

export default {
  name: 'MenuMaster',
  components: {
    MenuItems
  },
  props: ['pathLogin', 'pathLogout'],
  data() {
    return {
      store,
      ent_url: process.env.VUE_APP_ENT_URL,
      closeMenu,
      toggleMenuDetails,
    }
  },
  methods: {
    connexion() {
      window.location.href = this.pathLogin
    },
    logout() {
      store.clear()
      window.location.href = this.pathLogout
    },
  },
};
</script>

<style>
#ucaMenuLoader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #006D82;
  border-radius: 50%;
  margin-left: calc(50% - 25px);
  margin-top: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  90% { transform: rotate(300deg); }
  100% { transform: rotate(360deg); }
}


#menuMaster {
  flex: 0 1 auto;
  width: 100%;
  padding: 24px;
  background-color: #FFF;

  z-index: 63003;
}
#menuMaster > * {
  width: 100%;
}
#menuAccount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  color: #6D6D6C;
  background: #F7F7F7;
  border-radius: 16px;
}
#menuAccount a {
  text-decoration: none;
}
#menuAccount > #connexion {
  font-weight: bold;
  margin: 20px auto;
  text-align: center;
  padding: 8px 16px;
  background-color: #006D82;
  color: #FFF;
  border-radius: 8px;
  cursor: pointer;
}
#deconnexion, #close {
  cursor: pointer;
  color: inherit;
  border-radius: 50%;
  padding: 8px;
  height: 40px;/* svg 24px + 2 x 8px = 40px */
  margin-left: auto;
}
#deconnexion:hover, #close:hover {
  color: #f3f3f3;
  background-color: #006D82;
}
#userSpace {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 16px;
}
#nameSpace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#userName {
  padding: 5px;
}
#userName > span {
  font-size: 18px;
  font-weight: bold;
}
#userImage {
  object-fit: cover;
  width: 48px;
  height: 48px;
  border: 3px solid #006D82;
  border-radius: 50%;
}
#alert {
    color: #F39100;
    background: #f392002e;
    font-weight: 700;
    font-size: smaller;
    border-radius: 16px;
    padding: 16px;
    margin-top: 16px
}
.link-with-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.link-with-icon > *:not(:first-child) {
  margin-left: 8px;
}
.link-with-icon > a {
  color: #006D82;
}
.link-with-icon:hover > svg {
  color: #006D82;
}
.link-with-icon:hover > a {
  font-weight: 700;
}
#mainLinks {
  display: flex;
  width: 100%;
}
#mainLinks > *:not(:first-child):not(:last-child) {
  padding-left: 32px;
}
.menuTitle {
  font-size: 28px;
  margin-bottom: 0;
}
.menuSubTitle {
  font-size: 13px;
  margin: 8px 0 0;
  color: #6D6D6C;
}
#allApplications {
  display: none;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-right: 1rem;
}
#allApplications > a {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .3rem;
  color: inherit;
}
#allApplications > a:hover {
  color: #006D82;
  text-decoration: underline;
}

@media screen and (min-width: 769px) {
  #menuMaster {
    width: 400px;
  }
  #allApplications {
    display: flex;
  }
}
</style>