<template>
<div class="menuItems">
  <a :href="item.link" :target="item.redirect ? '_blank' : '_self'" class="menuItem" v-for="item in items" :key="item.id" @click="count(item.id)">
    <div class="itemIcon" v-bind:class="{ withDescription: withDescription }" v-if="item.icon" v-html="item.icon"></div>
    <div class="itemName">
      <div>{{ item.name }}</div>
      <div v-if="item.message" class="itemMessage" :class="item.message.type">{{ item.message.name }}</div>
      <div v-if="withDescription" class="itemDescription">{{ item.description }}</div>
    </div>
    <div v-if="item.update" class="itemUpdate">{{ item.update }}</div>
  </a>
</div>
</template>

<script>
import Menu from '@/api/menu.api'

export default {
  name: 'MenuItems',
  props: ['items', 'withDescription'],

  methods: {
    count(menuId) {
      Menu.accessCount(menuId)
    }
  }
}
</script>

<style>
.menuItems {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
  padding-top: 16px;
  /* height: inherit; */
}
.menuItem {
  display: flex;
  align-items: flex-start;
  padding: 12px 8px;
  border-bottom: 1px solid #E1E1E1;
  margin: 0 12px;
  color: inherit;
  text-decoration: none;
}
.menuItem:nth-child(odd) {
  justify-self: end;
}
.menuItem:hover {
  color: #006D82;
  background-color: #ECF8F9;
}
.menuItem:active {
  color: #FFF;
  background-color: #006D82;
}
.menuItem > div {
  align-self: flex-start;
}
.itemIcon {
  color: #6D6D6C;
}
.itemIcon > svg {
  width: 24px;
  height: 24px;
}
.itemIcon.withDescription > svg {
  width: 32px;
  height: 32px;
}
.itemName {
  padding: 2px 8px 0;
}
.itemMessage {
  display: inline-block;
  margin: 2px 0;
  padding: 2px 16px;
  border-radius: 16px;
  color: #3D3D3C;
  background-color: #F7EBDF;
  font-size: smaller;
}
.itemMessage.alert {
  color: white;
  background-color: #D60150;
}
/* .itemMessage.warning {
  color: white;
  background-color: #006D82;
} */
.itemDescription {
  padding-top: 5px;
  font-size: small;
  color: lightslategrey;
}
.itemUpdate {
  margin-left: auto;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  color: #FFF;
  font-size: 12px;
  padding: 4px;
  background-color: #006D82;
}
.menuItem:active .itemUpdate {
  color: #006D82;
  background-color: #ECF8F9;
}

@media screen and (min-width: 769px) {
  .categoryAndItems > .menuItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>